// @flow
import dayjs from "dayjs";
import styled from "styled-components";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Button, Grid, Loader, Popup, Table } from "semantic-ui-react";

import { ErrorWrap } from "../../helpers/error";
import { FilterButton } from "../../components/common/filter-button";
import { TransactionCreate } from "./transactions-page-create";
import { ContentFilterPanel } from "../../components/common/content-filter";

import { getUrlParams } from "../../components/common/urlparams";
import {
  cancelTransaction,
  fetchTransactions,
} from "../../redux/modules/fundTransaction";
import {
  ContentFilterFormBody,
  transactionQueryFilterParams,
  transactionQueryKeyMap,
} from "./transactions-page-content-filter";

import { amountToBGN } from "../../helpers/currency";
import { CancelTransactionForm } from "./transaction/cancel-transaction-form";
import { RoleRequired } from "../auth/role-required";
import FilterDisplay from "../../components/common/filter-display";
import { permissions } from "../../constant/permissions";
import { t } from "../../constant/translations";
import { Category } from "../management/transactions/category";
import { PageNavigationSimpleFromMeta } from "../../components/common/page-navigation";
import { Markdown } from "../../components/common/markdown";
import { LineBreakAnywhere } from "../../components/base/elements";

const StyledContentFilterPanel = styled(ContentFilterPanel)`
  min-height: 550px;
`;

const Inline = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: space-between;
`;

class BlockTransactionsPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filterOpen: false,
    };
  }

  componentDidMount() {
    this.getTransactions(this.props);
  }

  componentDidUpdate(oldProps) {
    if (oldProps.location.search !== this.props.location.search) {
      this.getTransactions(this.props);
    }
  }

  getFilters = (props) => {
    const argFilters = getUrlParams(props.location);
    if (props.block) {
      argFilters["block"] = props.block.id;
    }

    argFilters["funds_only"] = true;
    return argFilters;
  };

  handleSetFilterOpen = (filterOpen) => this.setState({ filterOpen });

  getTransactions(props) {
    props.fetchTransactions(this.getFilters(props));
  }

  renderTransactions() {
    const { transactions, loading, error, block } = this.props;

    if (!transactions) {
      return <Loader active />;
    }

    const transactionList = transactions.map((trans) => {
      return (
        <Table.Row key={trans.id} negative={trans.cancel_at}>
          <Table.Cell>{dayjs(trans.date).format("YYYY-MM-DD")}</Table.Cell>
          <Table.Cell>{trans.fund}</Table.Cell>
          <Table.Cell>{amountToBGN(trans.amount)}</Table.Cell>
          <Table.Cell>
            <LineBreakAnywhere>
              <Markdown content={trans.description} />
            </LineBreakAnywhere>
          </Table.Cell>
          <Table.Cell>
            <Category transaction={trans} />
          </Table.Cell>
          <Table.Cell collapsing={true}>
            {!trans.cancel_at && (
              <RoleRequired
                staff={true}
                permission={permissions.billingTransactionDelete}
              >
                <CancelTransactionForm
                  entity={trans}
                  error={error}
                  loading={loading}
                  action={this.props.cancelTransaction}
                />
              </RoleRequired>
            )}
            {trans.cancel_at && (
              <Popup
                position={"left center"}
                trigger={
                  <Button
                    icon={"info"}
                    size={"tiny"}
                    basic={true}
                    floated={"right"}
                  />
                }
              >
                <h4>
                  {t("billing.transaction_reversed_on")} {trans.cancel_at}
                </h4>
                <p>{trans.cancel_reason}</p>
              </Popup>
            )}
          </Table.Cell>
        </Table.Row>
      );
    });

    return (
      <Table unstackable={true}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t("labels.date")}</Table.HeaderCell>
            <Table.HeaderCell>{t("labels.direction")}</Table.HeaderCell>
            <Table.HeaderCell>{t("labels.amount")}</Table.HeaderCell>
            <Table.HeaderCell>{t("labels.description")}</Table.HeaderCell>
            <Table.HeaderCell>{t("labels.category")}</Table.HeaderCell>
            <Table.HeaderCell>
              <TransactionCreate block={block} />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{transactionList}</Table.Body>
      </Table>
    );
  }

  render() {
    const { meta } = this.props;
    return (
      <Grid padded columns="equal">
        <Grid.Row>
          <Grid.Column>
            <Inline>
              <div>
                <PageNavigationSimpleFromMeta meta={meta} />
              </div>
              <FilterDisplay keyMap={transactionQueryKeyMap} />
              <FilterButton
                onClick={() => this.handleSetFilterOpen(!this.state.filterOpen)}
              />
            </Inline>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ padding: "0 0" }}>
          <Grid.Column>
            <StyledContentFilterPanel
              open={this.state.filterOpen}
              onApply={() => this.handleSetFilterOpen(false)}
              queryParamConfig={transactionQueryFilterParams}
              FormBody={ContentFilterFormBody}
            >
              {this.renderTransactions()}
            </StyledContentFilterPanel>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    block: state.blocks.selected,
    error: ErrorWrap.fromError(state.fundTransaction.error),
    loading: state.fundTransaction.pending,
    transactions: state.fundTransaction.entities,
    meta: state.fundTransaction.meta,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ fetchTransactions, cancelTransaction }, dispatch);
};

export const BlockTransactionsPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(BlockTransactionsPageComponent);
