import React from "react";
import { Menu } from "semantic-ui-react";
import { FilterButton } from "../../../../components/common/filter-button";
import {
  PageNavigationSimple,
  determinePageNumber,
} from "../../../../components/common/page-navigation";

export const AuditTopMenu = ({ openFilter, previous, next }) => {
  return (
    <Menu stackable={true} size={"tiny"}>
      <Menu.Item>
        <PageNavigationSimple
          next={next}
          previous={previous}
          pageNumber={determinePageNumber(next, previous)}
        />
      </Menu.Item>
      <Menu.Item position={"right"}>
        <FilterButton onClick={openFilter} />
      </Menu.Item>
    </Menu>
  );
};
