import React from "react";
import styled from "styled-components";
import * as semantic from "semantic-ui-react";
import { Header, Menu } from "semantic-ui-react";

export const Grid = styled(semantic.Grid)`
  margin: 0 0 !important;
  padding: 1rem 0.4rem 1rem 0.4rem !important;
`;

export const Row = styled(semantic.Grid.Row)``;
export const Column = styled(semantic.Grid.Column)`
  padding-left: 0 !important;
  padding-right: 0 !important;
`;
export const Col = Column;

Grid.Column = Column;
Grid.Row = Row;

export const StyledTopMenu = styled(Menu)`
  padding: 0 0 !important;
  margin: 0 0 0.4rem 0 !important;
`;

export const Inline = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledHeader = styled(Header)({
  margin: "15px 0 5px 15px !important",
});

export const LineBreakAnywhere = styled.span`
  overflow-wrap: anywhere;
  word-break: break-word;
`;
