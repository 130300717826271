import React from "react";
import styled from "styled-components";
import { Button, Input, Label } from "semantic-ui-react";
import { useQueryParams } from "use-query-params";
import { t } from "../../constant/translations";
import qs from "qs";

const StyledLabel = styled.span`
  display: inline-block;
  margin-right: 3px;
  border: 1px solid #e0e0e0;
  font-size: 12px;
  border-radius: 5px;
  padding: 5px 8px;
  vertical-align: baseline;
`;

const GroupedButtons = styled.div`
  margin-top: 0 !important;
  margin-bottom: 0 !important;
`;

export const PageNavigationDetailed = ({
  previous,
  next,
  pageNumber,
  totalPages,
}) => {
  const [pq, pSetQuery] = useQueryParams({});
  const [nq, nSetQuery] = useQueryParams({});
  return (
    <Input size={"mini"} type={"text"}>
      <Button
        disabled={!previous}
        icon="angle legt"
        onClick={(e) => {
          if (previous) {
            const p = qs.parse(previous);
            pSetQuery({ ...p }, "replace");
          }
        }}
      />
      <Label basic>
        {t("common.page")} {`${pageNumber}`} {t("common.page_of")} {totalPages}
      </Label>
      <Button
        disabled={!next}
        icon="angle right"
        onClick={(e) => {
          if (next) {
            const n = qs.parse(next);
            nSetQuery({ ...n }, "replace");
          }
        }}
      />
    </Input>
  );
};

export const PageNavigationSimpleFromMeta = ({ meta }) => {
  if (!meta) {
    return <></>;
  }
  const [next, previous, pageNumber, totalPages] = determinePage(meta);
  return (
    <PageNavigationSimple
      previous={previous}
      next={next}
      pageNumber={pageNumber}
      totalPages={totalPages}
    />
  );
};

export const PageNavigationSimple = ({
  previous,
  next,
  pageNumber,
  totalPages,
}) => {
  const [pq, pSetQuery] = useQueryParams({});
  const [nq, nSetQuery] = useQueryParams({});
  return (
    <GroupedButtons>
      <Button
        disabled={!previous}
        basic={true}
        size="tiny"
        icon="angle double left"
        onClick={(e) => {
          const p = qs.parse(previous);
          pSetQuery({ ...p, page: 1 }, "replace");
        }}
      />
      <Button
        disabled={!previous}
        basic={true}
        size="tiny"
        icon="angle left"
        onClick={(e) => {
          if (previous) {
            const p = qs.parse(previous);
            pSetQuery({ ...p }, "replace");
          }
        }}
      />
      <StyledLabel size="tiny" basic={true}>
        {t("common.page")} {`${pageNumber}`}
      </StyledLabel>
      <Button
        disabled={!next}
        basic={true}
        size="tiny"
        icon="angle right"
        onClick={(e) => {
          if (next) {
            const n = qs.parse(next);
            nSetQuery({ ...n }, "replace");
          }
        }}
      />
    </GroupedButtons>
  );
};

export const determinePageNumber = (next, previous) => {
  let currentPage = 1;
  if (next) {
    currentPage = next.page ? parseInt(next.page) - 1 : 1;
  }

  if (previous) {
    currentPage = previous.page ? parseInt(previous.page) + 1 : 2;
  }

  return currentPage;
};

export const determinePage = (meta) => {
  let next, previous, currentPage, totalPages;

  if (meta.next) {
    next = qs.parse(meta.next.split("?")[1]);
    currentPage = next.page ? parseInt(next.page) - 1 : 1;
  }

  if (meta.prev) {
    previous = qs.parse(meta.prev.split("?")[1]);
    currentPage = previous.page ? parseInt(previous.page) + 1 : 2;
  }

  meta.count <= 25
    ? (totalPages = 1)
    : (totalPages =
        meta.count % 25 ? Math.ceil(meta.count / 25) : meta.count / 25);
  return [next, previous, currentPage, totalPages];
};
